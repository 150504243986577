import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import Pay from "views/Pay/Pay";
import PrivacyPolicy from "views/Pages/PrivacyPolicy";

const { REACT_APP_STRIPE_PUBLISHABLE_KEY = "" } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <div className="App">
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Pay />} />
              <Route path="/:tokenUUID" element={<Pay />} />
              <Route path="/pages/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </BrowserRouter>
        </Elements>
      </div>
    </QueryClientProvider>
  );
}

export default App;
