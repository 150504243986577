import { apiEndpoint } from "helpers/constants";

export const fetchPlaidLinkTokenAync = async () => {
    const endpoint = `${apiEndpoint}/plaid/token`;
  
    const response = await fetch(endpoint);
    const  {result: { link_token: linkToken = '' } = {} } = (await response.json()) || {};
    return linkToken;
};

export const exchangePublicTokenAndChargeCardAsync = async ({ tokenUUID, publicToken, accountId, email}: {
    tokenUUID: string, publicToken: string, accountId: string, email: string
}) => {
    const endpoint = `${apiEndpoint}/plaid/exchange-token-and-charge-bank?${new URLSearchParams({
        tokenUUID,
        publicToken,
        accountId,
        email
    })}`;
  
    const response = await fetch(endpoint);
    const result = (await response.json()) || {};
    return result;
}