import { useQuery } from 'react-query';
import { useCallback } from 'react';
import { createPaymentIntentAsync } from './paymentIntent';
import { fetchPayItemAsync } from './payItem';
import { exchangePublicTokenAndChargeCardAsync, fetchPlaidLinkTokenAync } from './plaid';
import { reactQueryStaleTime as staleTime } from '../helpers/constants';

export const usePayItem = (tokenUUID: string) => {
  const response = useQuery('tokenUUID', async () => fetchPayItemAsync({ tokenUUID }), {
    staleTime,
    refetchOnWindowFocus: false,
  });
  return response;
};

export const usePlaidLinkToken = () => {
  const response = useQuery('linkToken', async () => fetchPlaidLinkTokenAync(), {
    staleTime,
    refetchOnWindowFocus: false,
    retry: false
  });
  return response;
}

//tokenUUID is the pay/invoice identifier 
//publicToken is plaid's public token which will be used to exchange for an accessToken to use to charge bank account
export const useChargeBank = ({ tokenUUID, publicToken, accountId, email }: {tokenUUID : string, publicToken: string, accountId: string, email: string }) => {
  const response = useQuery('chargeBank', async () => exchangePublicTokenAndChargeCardAsync({ tokenUUID, publicToken, accountId, email }), {
    staleTime,
    refetchOnWindowFocus: false,
    enabled: !!accountId && !!publicToken,
    retry:  false
  });
  return response;
}

export const usePaymentIntent = () => {
  const createPaymentIntent = useCallback(
    async (tokenUUID: string) => {
      try {
        const paymentIntent = await createPaymentIntentAsync(tokenUUID);
        return paymentIntent;
      } catch (e) {
        return false;
      }
    },
    [],
  );
  return createPaymentIntent;
};

