import { FC } from "react";

interface PaidProps {
    show: boolean;
    completedAt?: string;
}

const Paid:FC<PaidProps> = (props) => {
    const { show, completedAt = '' } = props;
    const date = new Date(parseInt(completedAt) *1000)
    return show ? (
    <div className="flex flex-col items-center justify-center w-full mt-8">
        <div className="flex flex-col items-center justify-center text-sm text-white my-2 h-4 text-center">
            <span>{`Your payment was received on ${date}`}</span>
        </div>
    </div>
    ) : null
}

export default Paid;