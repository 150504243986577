import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { activeFormAtom, stripePaymentElementClientSecretAtom } from "atoms";
import { usePayItem, usePaymentIntent } from "state/hooks";
import { useParams } from "react-router-dom";
import Skeleton from './CreditCardSkeleton';
import PaymentStatus from './PaymentStatus';
import { paymentStatusAtom } from "atoms";

interface CreditCardFormProps {
    show: boolean;
}

const cardElementOptions = {
    style: {
      base: {
        color: '#ffffff',
        margin: '10px 0 20px 0',
        '::placeholder': {
          color: '#aaaaaa',
        },
        iconColor: '#FFFFFF',
      },
      invalid: {
        color: '#FF3954',
        iconColor: '#aaaaaa',
      },
    },
  };



const CreditCardForm:FC<CreditCardFormProps> = (props) => {
    const { show = false } = props;
    const [stripeElementComplete, setStripeElementComplete] = useState(false);
    const [status, setStatus] = useAtom(paymentStatusAtom);

    const isChargingCard = status === 'charging';
    const isChargeSuccess = status === 'charge-success';

    const [activeForm] = useAtom(activeFormAtom);
    let { tokenUUID = '' } = useParams();
    const { data: { email } } = usePayItem(tokenUUID);
    const [clientSecret, setClientSecret] = useAtom(stripePaymentElementClientSecretAtom);
    const createPaymentIntent = usePaymentIntent();
    const elements = useElements();
    const stripe = useStripe();
    const isCardActive = activeForm === 'card';
    const isButtonDisabled = !stripeElementComplete || !clientSecret
    
    useEffect(() => {
        if(!isCardActive) return;
        async function createPaymentIntentFn() {
          const data =
            (await createPaymentIntent(tokenUUID)) || {};
          setClientSecret(data);
        }
        createPaymentIntentFn();
      }, [createPaymentIntent, setClientSecret, isCardActive, tokenUUID]);

    const handleStripeElementChange = (e: any) => {
        setStatus('')
        if (e?.complete) {
          setStripeElementComplete(true);
        }
    };

    useEffect(() => {
      if(clientSecret) {
        const card = elements?.getElement(CardElement);
        card?.focus();
      }
    }, [clientSecret, elements])

    const chargeCard = async () => {
      if (!stripe || !elements) {
        return;
      }
      setStatus('charging')
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements?.getElement(CardElement) || { token: clientSecret },
          metadata: {
            email
          }
        },
      });

      if(response.error) {
        setStatus('error')
      } else {
        setStatus('charge-success')
      }
    };

    return show ? (
        <div className="flex flex-col items-center justify-center w-full md:mt-8 px-10 md:px-0">
           <PaymentStatus />
           <Skeleton show={!clientSecret} />
           {clientSecret && !isChargeSuccess && (<>
           <div className="w-full max-w-screen-sm py-4 px-6 mx-4 my-4 surface-5 text-2 rounded-lg bg-[#2e2e30]">
                <CardElement options={cardElementOptions} onChange={handleStripeElementChange} />
            </div>
            <button disabled={isButtonDisabled} onClick={() => chargeCard()}className={`flex items-center ${isButtonDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} justify-center mt-4 text-[#1F2224] text-sm rounded-sm bg-[#ffffff] py-2 px-8 opacity-90 hover:opacity-100 transition ease-in-out min-w-[145px]`}> 
              {isChargingCard
                ? 
                (<svg className="animate-spin h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                 </svg>
                ) :
              'Charge Card'
              }
            </button>
            </>)}
        </div>
    ) : null
};



export default CreditCardForm;