import { ChevronRightIcon } from "@heroicons/react/outline";
import { FC } from "react";

interface PayViaCardProps {
    onClick: () => void
}

const PayViaCardCard:FC<PayViaCardProps> = (props) => {
    const { onClick } = props;
    return (
        <div
            className="flex flex-col relative transition ease-in-out opacity-90  hover:opacity-100 justify-between w-full md:w-1/2 max-w-lg md:max-w-sm h-40 md:h-60 rounded-2xl md:mx-4 mt-10 md:mt-0 cursor-pointer py-8 px-8 text-[#e7e7e7] bg-gradient-to-r from-[#2788c1] to-[#23eac6]"
            onClick={onClick}
        >
            <span className="font-medium">Credit Card</span>
            <span className="text-sm font-medium">2.9% Transaction Fee</span>
            <div className="flex items-center absolute right-0 top-0 mr-6 h-full md:hidden">
                <ChevronRightIcon className="h-5 w-5 mr-2" />
            </div>
        </div>
    )
};

export default PayViaCardCard;