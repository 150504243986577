import React, { FC } from 'react';

interface SkeletonProps {
  show: boolean;
}

const SkeletonComponent: FC<SkeletonProps> = (props) => {
  const { show } = props;
  return show ? (
    <div className="flex flex-col md:mt-0 w-full max-w-screen-sm item-center justify-center">
      <span className="flex text-sm text-white mb-6 w-full justify-center">Please wait</span>
      <div className="relative pt-0 w-full overflow-hidden ">
        <div className="animate-pulse flex flex-col justify-center items-center">
          <div className="w-full h-12 bg-[#2e2e30] rounded-md" />
          <div className="w-[145px] h-9 bg-[#2e2e30] rounded-md mt-6" />
        </div>
      </div>
    </div>
  ) : null;
};

export default SkeletonComponent;