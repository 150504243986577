import { FC } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { activeFormAtom } from "atoms";

const PayViaBankCard:FC = (props) => {
    const [, setActiveForm] = useAtom(activeFormAtom);

    const handleOnClick = () => {
        setActiveForm('bank');
    }

    return (
        <div
            className="flex flex-col relative justify-between transition ease-in-out opacity-90 hover:opacity-100 w-full md:w-1/2 max-w-lg md:max-w-sm h-40 md:h-60 bg-[#2e2e30] rounded-2xl md:mx-4 mt-8 md:mt-0 cursor-pointer py-8 px-8 text-[#e7e7e7] bg-gradient-to-r from-[#B700FF] to-[#1764F2]"
            onClick={handleOnClick}
        >
            <span className="font-medium">Bank Account</span>
            <span className="text-sm font-medium">No Transaction Fee 😊</span>
            <div className="flex items-center absolute right-0 top-0 mr-6 h-full md:hidden">
                <ChevronRightIcon className="h-5 w-5 mr-2" />
            </div>
        </div>
    )
};

export default PayViaBankCard