import { formatter } from "helpers";
import { FC } from "react";
import { useAtom } from 'jotai';
import { activeFormAtom } from "atoms";

interface PayDetailsProps {
    show: boolean;
    amount?: number;
}

const PayDetails:FC<PayDetailsProps> = (props) => {
    const { show, amount = 0 } = props;
    const [activeForm] = useAtom(activeFormAtom);
    const cardActive = activeForm === 'card'

    return show ? (
    <div className="w-full flex flex-col items-center justify-center text-white">
        <span>{`${formatter.format(amount)}${cardActive ? ' + 2.9%' : ''}`}</span>
    </div>
    ) : null
}

export default PayDetails;