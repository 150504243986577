import {  ChevronLeftIcon } from '@heroicons/react/outline';
import { FC } from 'react';

interface BackButtonProps {
    onClick: () => void;
    show: boolean;
}

const BackButton:FC<BackButtonProps> = (props) => {
    const { onClick, show =  false } = props;
    return show ? (
        <div onClick={onClick} className="text-white flex items-center text-base container py-0 md:pl-40 cursor-pointer opacity-90 hover:opacity-100 transition ease-in-out">
            <ChevronLeftIcon className="h-4 w-4 mr-2" />
            <span>Back</span>
        </div>
    ) : null;
};

export default BackButton;