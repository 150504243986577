import PageWrap from "components/PageWrap/PageWrap";
import { FC } from "react";
import HeaderBar from "views/HeaderBar/HeaderBar";

const PrivacyPolicy: FC = () => (
  <>
    <HeaderBar />
    <PageWrap>
      <div className="container mt-12 flex flex-col text-white mx-auto">
        <span className="font-medium text-base">Privacy Policy</span>
        <br />
        <span className="font-medium text-sm">
          Last Updated: December 12th, 2022
        </span>
        <br />
        <span className="font-medium text-sm">
          This Privacy Policy explains how Bloke LLC ("we," "us," or "our")
          collects, uses, discloses, and safeguards the personal information of
          individuals ("users," "you," or "your") who visit or use our website
          and services. We are committed to protecting your privacy and ensuring
          the security of your personal information. Please read this Privacy
          Policy carefully to understand our practices.
        </span>
        <br />
        <span className="font-medium text-sm">Information We Collect</span>
        <br />
        <span className="font-medium text-sm my-1">
          1.1 Personal Information: When you visit our website or use our
          services, we may collect personal information that can identify you
          directly or indirectly. This information may include your name, email
          address, contact information, billing address, and payment details.
        </span>
        <span className="font-medium text-sm my-1">
          1.2 Financial Information: If you use our services to make payments or
          process transactions, we may collect financial information, including
          bank account details and credit/debit card information. We use
          third-party payment processors, such as Stripe, to handle these
          transactions. Please refer to Stripe's Privacy Policy for more
          information on how they collect, use, and protect your financial
          information.
        </span>
        <span className="font-medium text-sm my-1">
          1.3 Plaid: We may use Plaid to gather information from your financial
          institution accounts with your consent. Plaid's privacy practices are
          governed by its own Privacy Policy, and you should review it for more
          information on how they handle your data.
        </span>
        <br />
        <span className="font-medium text-sm">How We Use Your Information</span>
        <br />
        <span className="font-medium text-sm my-1">
          2.1 We use the information we collect to provide and improve our
          services, communicate with you, and ensure the security of our
          platform. Specifically, we may use your information to: Process and
          fulfill your orders or transactions Verify your identity and prevent
          fraud Respond to your inquiries or requests Customize and personalize
          your experience Send you administrative messages, updates, and
          promotional materials Conduct data analysis and research to improve
          our services
        </span>
        <span className="font-medium text-sm my-1">
          2.2 We may also use your information in an aggregated and anonymized
          form for statistical and analytical purposes.
        </span>
        <br />
        <span className="font-semibold text-sm my-1">
          Information Sharing and Disclosure
        </span>
        <br />
        <span className="font-medium text-sm my-1">
          3.1 We may share your personal information with trusted third-party
          service providers who assist us in operating our website, conducting
          our business, and providing our services. These third-party providers
          are contractually obligated to protect your information and use it
          only for the purposes we specify.
        </span>
        <span className="font-medium text-sm my-1">
          3.2 We may also disclose your personal information if required by law,
          legal process, or government request, or if we believe it is necessary
          to protect our rights, property, or safety, or that of others.
        </span>
        <br />
        <span className="font-semibold text-sm">Data Security</span>
        <br />
        <span>
          4.1 We implement appropriate security measures to protect your
          personal information from unauthorized access, disclosure, alteration,
          or destruction. However, please be aware that no security system is
          completely foolproof, and we cannot guarantee the absolute security of
          your data.
        </span>
        <br />
        <span className="font-semibold text-sm">Your Choices and Rights</span>
        <br />
        <span className="font-medium text-sm my-1">
          5.1 You have the right to access, update, correct, and delete your
          personal information held by us. If you wish to exercise any of these
          rights or have any questions or concerns about your privacy, please
          contact us using the contact information provided at the end of this
          Privacy Policy.
        </span>
        <br />
        <span className="font-semibold text-sm">
          Third-Party Links and Websites
        </span>
        <br />
        <span className="font-medium text-sm my-1">
          6.1 Our website may contain links to third-party websites or services.
          This Privacy Policy does not apply to those third-party websites or
          services, and we are not responsible for their privacy practices. We
          encourage you to review the privacy policies of those third parties
          before providing any personal information.
        </span>
        <br />
        <span className="font-semibold text-sm ">
          Changes to this Privacy Policy
        </span>
        <br />
        <span className="font-medium text-sm my-1">
          7.1 We may update this Privacy Policy from time to time to reflect
          changes in our practices or applicable laws. We will notify you of any
          material changes by posting the updated Privacy Policy on our website
          and updating the "Last Updated" date at the top. We encourage you to
          review this Privacy Contact Us If you have any questions or concerns
          regarding this Privacy Policy or our privacy practices, please contact
          us at: Bloke LLC 30856 Agoura Road Agoura Hills, CA, 91301
          hey@blokestudios.com 646-709-0470
        </span>
      </div>
    </PageWrap>
  </>
);

export default PrivacyPolicy;
