import { useAtom } from 'jotai';
import { paymentStatusAtom, activeFormAtom } from "atoms";

const PaymentStatus = () => {
  const [status] = useAtom(paymentStatusAtom);
  const [activeForm] = useAtom(activeFormAtom);
  const isChargingCard = status === 'charging';
  const isError = status === 'error';
  const isChargeSuccess = status === 'charge-success';
  const isCard = activeForm === 'card';

  return (
    <div className="text-sm text-white my-2 h-4">
      {isChargingCard && <span className="animate-pulse">{`Please wait, attempting to charge ${isCard ? 'card' : 'bank account'}`}</span>}
      {isError && <span>An error occured. Plesae try again</span>}
      {isChargeSuccess && <span>Thank you! Your payment has been received.</span>}
    </div>
  )
}

export default PaymentStatus;