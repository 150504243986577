import PageWrap from "components/PageWrap/PageWrap";
import { useAtom } from 'jotai';
import BackButton from 'views/Pay/components/BackButton';
import CreditCardForm from 'views/Pay/components/CreditCardForm';
import BankForm from 'views/Pay/components/BankForm';
import PaymentMethods from 'views/Pay/components/PaymentMethods';
import Paid from 'views/Pay/components/Paid';
import PayDetails from 'views/Pay/components/PayDetails';
import HeaderBar from "views/HeaderBar/HeaderBar";
import { usePayItem } from "state/hooks";
import { useParams } from "react-router-dom";
import { activeFormAtom, paymentStatusAtom } from "atoms";
import Home from "views/Home/Home";

const Pay = () => {
    const [activeForm, setActiveForm] = useAtom(activeFormAtom);
    const [paymentStatus] = useAtom(paymentStatusAtom);
    const { tokenUUID = '' } = useParams();
    const { data: {amount, paymentMethods, status, completedAt } = {}, isLoading } = usePayItem(tokenUUID)
    const cardActive = activeForm === 'card';
    const bankActive = activeForm === 'bank';
    const paymentComplete = status === 'paid';
    const isChargeSuccess = paymentStatus === 'charge-success';
    const showBackonBankForm = paymentStatus === "error" && bankActive

    if(!tokenUUID || (!isLoading && !amount)) return <Home />

    return !isLoading  ? (
        <>
        <HeaderBar />
        <PageWrap>
            <BackButton show={cardActive || showBackonBankForm} onClick={() => setActiveForm('')}/>
            <PayDetails show={!paymentComplete && !isChargeSuccess} amount={amount}/>
            <PaymentMethods methods={paymentMethods} show={!activeForm && !paymentComplete} setActiveForm={setActiveForm}/>
            <CreditCardForm show={cardActive && !paymentComplete} />
            <BankForm show={bankActive && !paymentComplete}/>
            <Paid show={paymentComplete} completedAt={completedAt} />
        </PageWrap>

        </>
    ) : null;
};

export default Pay;