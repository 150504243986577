import { FC, useCallback, useEffect, useState } from "react";
import { useChargeBank, usePayItem, usePlaidLinkToken } from "state/hooks";
import { PlaidLinkError, PlaidLinkOnExitMetadata, usePlaidLink } from "react-plaid-link";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { activeFormAtom, paymentStatusAtom } from "atoms";
import PaymentStatus from "./PaymentStatus";

interface BankFormProps {
     show: boolean;
}

const Paid:FC<BankFormProps> = (props) => { 
  const { show } = props;
  const { data } = usePlaidLinkToken();
  let { tokenUUID = '' } = useParams();
  const { data: { email } = {} } = usePayItem(tokenUUID)
  const [linkToken, setLinkToken] = useState('');
  const [accountId, setAccountId] = useState('');
  const [publicToken, setPublicToken] = useState('');
  const [status, setStatus] = useAtom(paymentStatusAtom);
  const [, setActiveForm] = useAtom(activeFormAtom);
  const isError = status === 'error';

  const { data: chargeResponse, isLoading } = useChargeBank({ publicToken, tokenUUID, accountId, email });

  useEffect(() => {
    if(chargeResponse && !isLoading) {
        if (chargeResponse?.ok && chargeResponse?.result === "payment_complete") {
          setStatus('charge-success');
        } else {
          setStatus('error');
        }
    };
  }, [chargeResponse, isLoading, setStatus])

  useEffect(() => {
    if(data && !linkToken) {
        setLinkToken(data)
    }
  }, [data, linkToken]);

  const onSuccess = useCallback(async (publicToken: string, metadata: any) => {
    setStatus('charging');
    const { account_id: accountId } = metadata;
    setAccountId(accountId);
    setPublicToken(publicToken);
  }, [setStatus, setAccountId, setPublicToken]);

  const onExit = useCallback(async (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
    if (error) {
      setStatus('error');
    } else {
      setActiveForm('');
    }
  }, [setStatus, setActiveForm]);

  const linkConfig = {token: linkToken, onSuccess, onExit};

  const { open, ready } = usePlaidLink(linkConfig);

  useEffect(() => {
    if(ready && show && !chargeResponse) open();
  }, [ready, show, open, chargeResponse])
  
  return show ? (
    <div className="flex flex-col items-center justify-center w-full mt-8">
      <PaymentStatus />
      {isError && (
       <button onClick={() => { open(); setStatus('')}} className="flex items-center cursor-pointer justify-center mt-4 text-[#1F2224] text-sm rounded-sm bg-[#ffffff] py-2 px-8 opacity-90 hover:opacity-100 transition ease-in-out min-w-[145px]"> 
         Retry
        </button>)}
    </div>
  ) : null
}

export default Paid;