import { FC, ReactNode } from "react";

interface PageWrapProps {
    children: ReactNode
}

const PageWrap: FC<PageWrapProps> = (props) => {
    const { children } = props;
    return (
        <div className="mt-8 text-lg">
            {children}
        </div>
    )
};

export default PageWrap;