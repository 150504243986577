import { apiEndpoint } from "helpers/constants";

export const createPaymentIntentAsync = async (tokenUUID: string) => {
  const url = `${apiEndpoint}/paymentIntent/create`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ tokenUUID }),
    headers: {
      'Content-Type': 'application/json'
    },
  })

  const data = await response.json();

  return data?.result?.clientSecret;
};
