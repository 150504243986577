import { apiEndpoint } from "helpers/constants";

export const fetchPayItemAsync = async ({
  tokenUUID,
}: {
  tokenUUID: string;
}) => {
  const endpoint = `${apiEndpoint}/${tokenUUID}`;

  const response = await fetch(endpoint);
  const  { result } = (await response.json()) || {};

  return result;
};
