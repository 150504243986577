import { FC } from "react";
import PayViaBankCard from "./PayViaBankCard";
import PayViaCardCard from "./PayViaCardCard";

interface PaymentMethodsProps {
    show: boolean;
    setActiveForm: (value: string) => void;
    methods: ('cc' | 'ach' )[]
}
const PaymentMethods:FC<PaymentMethodsProps> = (props) => {
    const { show = false, setActiveForm, methods  } = props;
    const bankActive = methods.find((elem) => elem === 'ach');
    const ccActive = methods.find((elem) => elem === 'cc');

    return show ? (
        <div className="flex flex-col md:flex-row mt-4 md:mt-12 px-6 md:px-4 w-full items-center justify-center">
            {bankActive && <PayViaBankCard />}
            {ccActive && <PayViaCardCard onClick={() => setActiveForm('card')}/>}
        </div>
    ) : null
}

export default PaymentMethods;